import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit, Home, Person, RemoveRedEye } from "@material-ui/icons";
import { lighten } from "polished";
import React from "react";
import { useHistory } from "react-router-dom";
import { useEditMode } from "../../hooks/useEditMode";
import "../../pages/pages/QRCode.css";

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: `${theme.spacing(1) / 4}px ${theme.spacing(4)}px`,
		background:
			// Si la couleur primaire et la couleur tertiaire sont les mêmes, ça veut dire que la société n'as pas de couleur tertiaire, donc on éclairci la couleur primaire
			theme.palette.primary.main === theme.sidebar.footer.background
				? lighten(0.1, theme.sidebar.footer.background)
				: theme.sidebar.footer.background,
		color: theme.sidebar.footer.color,
		position: "sticky",
		zIndex: 1,
		bottom: 0,
		left: 0,
	},
}));

export default React.forwardRef((props, ref) => {
	const classes = useStyles();
	const { isEditMode, setIsEditMode } = useEditMode();
	const history = useHistory();

	return (
		<div className={classes.wrapper} ref={ref}>
			<Grid container spacing={0}>
				<Grid container item xs={12} md={6} justify="space-around">
					{/* <Paper square>
						<Tabs value={value} onChange={handleChange} variant="fullWidth" indicatorColor="primary">
							{window.location.pathname !== "/" && <Tab icon={<Home />} />}
							{window.location.pathname !== "/directory" && <Tab icon={<Contacts />} />}
							{window.location.pathname === "/" && <Tab icon={isEditMode ? <RemoveRedEye /> : <Edit />} />}
							<Tab icon={<QRCodeIcon />} />
						</Tabs>
					</Paper> */}

					{/* Home */}
					{window.location.pathname !== "/" && (
						<IconButton
							variant="contained"
							style={{ color: "inherit" }}
							onClick={() => {
								setIsEditMode(false);
								history.push("/");
							}}
						>
							<Home />
						</IconButton>
					)}
					{/* Paramètre profil */}
					{window.location.pathname !== "/settings" && (
						<IconButton
							variant="contained"
							style={{ color: "inherit" }}
							onClick={() => {
								setIsEditMode(false);
								history.push("/settings");
							}}
						>
							{<Person />}
						</IconButton>
					)}
					{/* Mode d'édition */}
					{["/", "/settings"].indexOf(window.location.pathname) !== -1 && (
						<IconButton variant="contained" style={{ color: "inherit" }} onClick={() => setIsEditMode(!isEditMode)}>
							{isEditMode ? <RemoveRedEye /> : <Edit />}
						</IconButton>
					)}
					{/* Annuaire */}
					{/* <IconButton
						variant="contained"
						style={{ color: "inherit" }}
						onClick={() => history.push("/directory")}
						disabled={window.location.pathname === "/directory"}
					>
						<Contacts />
					</IconButton> */}
				</Grid>
			</Grid>
		</div>
	);
});
