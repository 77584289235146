// #region Configuration des tableaux avancés
const ENHANCED_TABLE_SETTINGS = "ENHANCED_TABLE_SETTINGS";

function filterProps({ id, active, ...rest }) {
	return { id, active, ...rest };
}

export const mergeHeadCells = (arr1, arr2) => {
	// On ne sauvegarde que certaines propriétés
	arr1 = arr1.map(filterProps);
	arr2 = arr2.map(filterProps);

	let ids = new Set(arr1.map((d) => d.id));
	return [...arr1, ...arr2.filter((d) => !ids.has(d.id))];
};

export const getEnhancedTableSettings = (id) => {
	try {
		let settings = JSON.parse(localStorage.getItem(ENHANCED_TABLE_SETTINGS) ?? "{}");

		return !!id ? settings[id] : settings;
	} catch (e) {
		return {};
	}
};

export const setEnhancedTableSettings = (enhancedTableSettings) => {
	enhancedTableSettings = JSON.stringify(enhancedTableSettings);
	localStorage.setItem(ENHANCED_TABLE_SETTINGS, enhancedTableSettings);
};

export const setEnhancedTableSettingsById = (id, newSettings) => {
	const settings = getEnhancedTableSettings();
	if (!settings[id]) {
		settings[id] = newSettings;
	} else {
		settings[id] = { ...settings[id], ...newSettings };
	}

	setEnhancedTableSettings(settings);
};
// #endregion

// #region Mode développeur
const DEV_MODE = "DEV_MODE";

export const getDevMode = () => {
	return localStorage.getItem(DEV_MODE) === "true";
};

export const setDevMode = (devMode) => {
	localStorage.setItem(DEV_MODE, devMode);
};

export const removeDevMode = () => localStorage.removeItem(DEV_MODE);

document.addEventListener("keydown", (event) => {
	if (event.ctrlKey && event.altKey && event.key.toLowerCase() === "d") {
		let newDevMode = !getDevMode();
		setDevMode(newDevMode);

		// #region Affichage d'un snackbar avec l'état actuel du DevMode
		// ? Oui, c'est moche d'utiliser un event prévu pour les erreurs d'API pour ça,
		// ? mais j'ai la flemme de faire un autre composant qui nous permet d'afficher un snackbar de manière programmatique, merci React...
		let evt = new CustomEvent("api_error", { detail: { status: 200, message: `DevMode ${newDevMode ? "activé" : "désactivé"}` } });
		document.dispatchEvent(evt);
		// #endregion Affichage d'un snackbar avec l'état actuel du DevMode
	}
});
// #endregion

// #region Enregistrement de l'ouverture du menu latérale
const SIDEBAR_OPEN = "SIDEBAR_OPEN";

export const getSidebarOpen = () => {
	let storage = localStorage.getItem(SIDEBAR_OPEN);

	if (storage === null) return false;
	else return storage === "true";
};

export const setSidebarOpen = (sidebarOpen) => {
	localStorage.setItem(SIDEBAR_OPEN, sidebarOpen);
};
// #endregion Enregistrement de l'ouverture du menu latérale
