import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { primaryText } from "../../theme/variants";

function Page404() {
	const history = useHistory();

	const { t } = useTranslation();

	return (
		<>
			<Helmet title={t("translation:notFound")} />

			{/**
			 * Ouais.. c'est moche comme solution, mais honnêtement, ça à le mérite de fonctionner...
			 * Je sais pas pourquoi, mais sur cette page, impossible de changer la couleur des typography
			 * */}
			<style>
				{`body {
					color: ${primaryText};
					text-align: center;
				}`}
			</style>

			<Grid container direction="column" alignItems="center" spacing={3}>
				<Grid item xs={12}>
					<Typography component="h1" variant="h1">
						{"404"}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography component="h2" variant="h5">
						{t("translation:notFound")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography component="h2" variant="body1">
						{t("translation:notFoundP")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button onClick={() => history.push("/")} variant="contained" color="secondary">
						{t("translation:backHome")}
					</Button>
				</Grid>
			</Grid>
		</>
	);
}

export default Page404;
