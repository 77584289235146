import { SnackbarProvider } from "material-ui-snackbar-provider";
import React from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { isLoggedIn, parseJwt } from "../api";
import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import PublicProfileLayout from "../layouts/PublicProfile";
import Page404 from "../pages/auth/Page404";
import { CustomSnackbar } from "../pages/components/Snackbar";
import { auth as authRoutes, dashboard as dashboardRoutes, publicProfile as publicProfileRoutes } from "./index";

function renderRoute(props, Layout, Component, requiresAuth, requiresAdmin = false) {
	if ((requiresAuth && !isLoggedIn()) || (requiresAdmin && (!isLoggedIn() || !parseJwt().is_admin))) {
		return <Redirect to="/login" />;
	}

	return (
		<Layout>
			<Component {...props} />
		</Layout>
	);
}

function childRoutes(Layout, routes, requiresAuth) {
	return routes.map(({ children, path, component: Component, requiresAdmin }, index) =>
		children ? (
			// Route item with children
			children.map(({ path, component: Component }, index) => (
				<Route key={index} path={path} exact render={(props) => renderRoute(props, Layout, Component, requiresAuth, requiresAdmin)} />
			))
		) : (
			// Route item without children
			<Route key={index} path={path} exact render={(props) => renderRoute(props, Layout, Component, requiresAuth, requiresAdmin)} />
		)
	);
}

export default function Routes() {
	return (
		<SnackbarProvider SnackbarComponent={CustomSnackbar}>
			<Router>
				<Switch>
					{childRoutes(DashboardLayout, dashboardRoutes, true)}
					{childRoutes(AuthLayout, authRoutes, false)}
					{childRoutes(PublicProfileLayout, publicProfileRoutes, false)}
					<Route
						render={() => (
							<AuthLayout>
								<Page404 />
							</AuthLayout>
						)}
					/>
				</Switch>
			</Router>
		</SnackbarProvider>
	);
}
