import { AppBar, Dialog, DialogContent, DialogTitle, Grid, Fab as MuiFab, Slide, Typography, makeStyles } from "@material-ui/core";
import { ChevronLeft, Close, Menu as MenuIcon } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { parseJwt } from "../../api";
import { IconButton as SolIconButton } from "../../pages/components";
import "../../pages/pages/QRCode.css";
import variants from "../../theme/variants";
import { ReactComponent as QRCodeIcon } from "../../vendor/qr_code.svg";

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		justifyContent: "space-between",
		background: "transparent",
		color: "initial",
		padding: theme.spacing(2),
		boxShadow: "none",
	},
	appBarShift: {
		width: `calc(100% - ${theme.sidebar.width}px)`,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	drawer: {
		width: theme.sidebar.width,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	fab: {
		background: theme.palette.primary.contrastText,
		color: theme.palette.primary.main,
	},
}));

function Fab({ children, ...props }) {
	const classes = useStyles();
	return (
		<MuiFab className={classes.fab} {...props}>
			{children}
		</MuiFab>
	);
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function ShareProfileDialog({ open, setOpen }) {
	const { t } = useTranslation();

	return (
		<Dialog fullScreen TransitionComponent={Transition} open={open} onClose={() => setOpen(false)}>
			<DialogTitle
				style={{
					backgroundColor: variants[0].palette.primary.main,
					color: variants[0].palette.primary.contrastText,
				}}
			>
				<Grid container justify="space-between" alignItems="center">
					<Typography>{"Partager mon profil"}</Typography>
					<SolIconButton color="inherit" onClick={() => setOpen(false)} icon={<Close />} title={t("translation:closeQRCode")} size="small" />
				</Grid>
			</DialogTitle>

			<DialogContent className="QRCodeContainer">
				<Grid container>
					<QRCode className="QRCode" value={`${window.location.origin}/p/${parseJwt()?.username}`} />
				</Grid>
			</DialogContent>
		</Dialog>
	);
}

function ShareMenu() {
	const [shareProfileOpen, setShareProfileOpen] = useState(false);

	return (
		<>
			{/* Partage du profile */}
			<ShareProfileDialog open={shareProfileOpen} setOpen={setShareProfileOpen} />
			<Fab onClick={() => setShareProfileOpen(true)} aria-label="Partager">
				<QRCodeIcon />
			</Fab>
		</>
	);
}

export default function Header({ open, setOpen }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<AppBar
			className={clsx(classes.appBar, {
				[classes.appBarShift]: open,
			})}
		>
			<Fab onClick={() => setOpen(!open)} aria-label={t(`translation:${open ? "close" : "open"}Drawer`)}>
				{open ? <ChevronLeft /> : <MenuIcon />}
			</Fab>
			<ShareMenu />
		</AppBar>
	);
}
