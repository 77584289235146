import { useTranslation } from "react-i18next";

export const isValidDate = (d) => {
	return d instanceof Date && !isNaN(d);
};

export const isDateNull = (d) => {
	if (d === "01-01-0001" || d === "0001-01-01") return true;
	if (isValidDate(d) && d.getTime() <= -62135596800000) return true;
	return false;
};

export const useMonthName = (monthNumber) => {
	const { i18n } = useTranslation();

	if (isNaN(monthNumber)) return null;

	const date = new Date();
	date.setMonth(monthNumber - 1);

	const locale = i18n.language === "fr" ? "fr-FR" : "en-US";

	return date.toLocaleString(locale, {
		month: "long",
	});
};

export const formatDateLong = (d, locale) => {
	if (isDateNull(d) || !isValidDate(d)) return "";

	return new Date(d).toLocaleDateString(locale, {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	});
};

export const formatDateShort = (d, locale) => {
	if (isDateNull(d) || !isValidDate(d)) return "";

	return new Date(d).toLocaleDateString(locale);
};
