import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import Item from "./GridSystem/Item";

/**
 * Englobe chaque enfant dans un <Grid item> avec des paramètres par défaut
 * @param {React.Props} props Propriétés du composant
 * @returns
 */
const Props = ({ children, ...rest }) => {
	if (!Array.isArray(children)) return null;

	return children.map((child, i) =>
		!React.isValidElement(child) ? null : (
			<Item key={child?.props?.prop || i} item xs={12} {...rest}>
				{child}
			</Item>
		)
	);
};

Props.PropTypes = {
	children: PropTypes.node.isRequired,
	xs: PropTypes.number,
	sm: PropTypes.number,
	md: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number,
};

function PropsAreEqual(prev, next) {
	return (
		isEqual(prev.children, next.children) &&
		prev.xs === next.xs &&
		prev.sm === next.sm &&
		prev.md === next.md &&
		prev.lg === next.lg &&
		prev.xl === next.xl
	);
}

export default React.memo(Props, PropsAreEqual);
