export function numberWithSpaces(x, precision = 2, separator = ",") {
	if (isNaN(x)) return null;

	// x = 65432.1 => ["65432", "1"]
	var parts = x.toString().split(".");
	// "65432" => "65 432"
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

	// Si on a un chiffre décimal
	if (parts.length > 1) {
		// "1" => "10"
		parts[1] = parts[1].padEnd(precision, "0");
		parts[1] = parts[1].substr(0, precision);
	} else {
		parts.push("00");
	}

	// ["65 432", "01"] => "65 432,01"
	return parts.join(separator);
}

export function formatServerDateForInput(date) {
	date = new Date(date);

	return new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60 * 1000));
}

export function decimalToHours(hours) {
	if (isNaN(hours)) return "00:00";

	var n = new Date(0, 0); // 01/01/1900
	n.setSeconds(+hours * 60 * 60);

	return n.toTimeString().slice(0, 5);
}
