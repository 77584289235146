import { isEqual } from "lodash";
import React, { createContext, useMemo, useState } from "react";
import { parseJwt, removeToken, setToken } from ".";
import { isNullOrEmpty } from "./strings";

export const getInitials = (user) => (!user.flip_name ? `${user.firstname[0]}${user.lastname[0]}` : `${user.lastname[0]}${user.firstname[0]}`);
export const getFullName = (user) => (!user.flip_name ? `${user.firstname} ${user.lastname}` : `${user.lastname} ${user.firstname}`);
export const getCompanyPosition = (user, withAdmin = false) =>
	`${withAdmin && user.is_admin ? "[Admin] " : ""}${!isNullOrEmpty(user.company_position) ? user.company_position : ""}`;
export const getCompanyTitle = (user) => (!isNullOrEmpty(user?.company?.title) ? user.company.title : "");

// #region Contexte de l'utilisateur
export const UserContext = createContext();

const MemoizedUserProvider = React.memo(
	({ children, value }) => {
		return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
	},
	(prevProps, nextProps) => {
		return isEqual(prevProps.value.user, nextProps.value.user);
	}
);

export const UserProvider = ({ children }) => {
	const [user, setUserState] = useState(parseJwt());

	function setUser(user) {
		if (user === null) {
			removeToken();
			setUserState(null);
		} else {
			setToken(JSON.stringify(user));
			setUserState((prevUser) => ({ ...prevUser, ...user }));
		}
	}

	const providerValue = useMemo(() => ({ user, setUser }), [user]);

	return <MemoizedUserProvider value={providerValue}>{children}</MemoizedUserProvider>;
};
// #endregion Contexte de l'utilisateur
