function createShadow(px) {
	return `0 0 ${px}px 0 rgba(53,64,82,.05)`;
}

const shadows = [
	"none",
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
	createShadow(14),
];

export default shadows;
