import { getContrast, transparentize } from "polished";
import { parseJwt } from "../api";

const user = parseJwt();

export const primary = user?.company?.color_primary || "#1D3557";
export const secondary = user?.company?.color_secondary || "#E73A46";
export const tertiary = user?.company?.color_tertiary || "#E73A46";

export const primaryText = getContrast(primary, "#EEE") < 4.5 ? "#000" : "#EEE";
export const secondaryText = getContrast(secondary, "#EEE") < 4.5 ? "#000" : "#EEE";
export const tertiaryText = getContrast(tertiary, "#EEE") < 4.5 ? "#000" : "#EEE";

const mainTheme = {
	name: "Main",
	spacing: 4,
	palette: {
		primary: {
			main: primary,
			contrastText: primaryText,
		},
		secondary: {
			main: secondary,
			contrastText: secondaryText,
		},
		// text: {
		// 	primary: contrastText,
		// 	secondary: lighten(0.2, contrastText),
		// 	disabled: darken(0.3, contrastText),
		// },
		// background: {
		// 	paper: text,
		// },
		divider: transparentize(0.7, primaryText),
	},
	body: {
		background: primary,
	},
	sidebar: {
		width: 260,
		color: secondaryText,
		background: secondary,
		header: {
			color: secondaryText,
			background: secondary,
		},
		footer: {
			color: tertiaryText,
			background: tertiary,
			online: {
				background: "#FFF",
			},
		},
		category: {
			fontWeight: 400,
		},
		badge: {
			color: "#000",
			background: "#FFF",
		},
	},
};

// Utile uniquement pour actualiser le theme dynamiquement quand l'utilisateur modifie sa société
export function getThemeFromUser(user) {
	const primary = user?.company?.color_primary || "#1D3557";
	const secondary = user?.company?.color_secondary || "#E73A46";
	const tertiary = user?.company?.color_tertiary || primary;

	const primaryText = getContrast(primary, "#EEE") < 4.5 ? "#000" : "#EEE";
	const secondaryText = getContrast(secondary, "#EEE") < 4.5 ? "#000" : "#EEE";
	const tertiaryText = getContrast(tertiary, "#EEE") < 4.5 ? "#000" : "#EEE";

	return {
		name: "Main",
		spacing: 4,
		palette: {
			primary: {
				main: primary,
				contrastText: primaryText,
			},
			secondary: {
				main: secondary,
				contrastText: secondaryText,
			},
			// text: {
			// 	primary: contrastText,
			// 	secondary: lighten(0.2, contrastText),
			// 	disabled: darken(0.3, contrastText),
			// },
			// background: {
			// 	paper: text,
			// },
			divider: transparentize(0.7, primaryText),
		},
		body: {
			background: primary,
		},
		sidebar: {
			width: 260,
			color: secondaryText,
			background: secondary,
			header: {
				color: secondaryText,
				background: secondary,
			},
			footer: {
				color: tertiaryText,
				background: tertiary,
				online: {
					background: "#FFF",
				},
			},
			category: {
				fontWeight: 400,
			},
			badge: {
				color: "#000",
				background: "#FFF",
			},
		},
	};
}

export default [mainTheme];
