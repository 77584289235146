const props = {
	MuiButtonBase: {
		// disableRipple: true,
	},
	MuiCardHeader: {
		titleTypographyProps: { variant: "h6" },
	},
	MuiTextField: {
		variant: "outlined",
	},
	FormHelperText: {
		variant: "outlined",
	},
	MuiFormControl: {
		variant: "outlined",
	},
	Autocomplete: {
		variant: "outlined",
	},
};

export default props;
