import { enUS, frFR } from "@material-ui/core/locale";
import { createMuiTheme } from "@material-ui/core/styles";

import breakpoints from "./breakpoints";
import overrides from "./overrides";
import props from "./props";
import shadows from "./shadows";
import typography from "./typography";
import variants from "./variants";

export const generateTheme = (variant, lang) => {
	const locale = lang === "fr" ? frFR : enUS;

	return createMuiTheme(
		{
			spacing: 4,
			breakpoints: breakpoints,
			overrides: overrides,
			props: props,
			typography: typography,
			shadows: shadows,
			body: variant.body,
			header: variant.header,
			palette: variant.palette,
			sidebar: variant.sidebar,
			footer: variant.footer,
		},
		variant.name,
		locale
	);
};

const themes = variants.map((variant) => (lang) => generateTheme(variant, lang));

export default themes;
