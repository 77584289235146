import { CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { parseJwt } from "../api";
import { getSidebarOpen, setSidebarOpen } from "../api/localStorage";
import ErrorsApi from "./ErrorsApi";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar/Sidebar";

// #region Customisation de certains éléments
const Root = styled.div`
	display: flex;
`;

const Main = styled.main`
	flex-grow: 1;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background: ${(props) => props.theme.body.background};
  }
`;

const useStyles = makeStyles((theme) => ({
	toolbar: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	companyLogo: {
		maxWidth: "80%",
		paddingBottom: theme.spacing(5),
	},
	main: {
		padding: theme.spacing(5) + "px",
		[theme.breakpoints.up("lg")]: {
			padding: theme.spacing(10) + "px",
		},
	},
}));
// #endregion Customisation de certains éléments

export default function Dashboard({ children }) {
	const history = useHistory();
	const classes = useStyles();
	const [open, setOpen] = React.useState(getSidebarOpen());

	const setOpenAndSave = (state) => {
		setOpen(state);
		setSidebarOpen(state);
	};

	// #region Calcul de la hauteur de main en prennant en compte la taille du Footer
	const footerRef = React.useRef(null);
	const [footerHeight, setFooterHeight] = React.useState(0);
	useLayoutEffect(() => {
		setFooterHeight(footerRef?.current?.offsetHeight || 0);
	}, [footerRef]);
	// #endregion Calcul de la hauteur de main en prennant en compte la taille du Footer

	const logo = parseJwt()?.company?.path_to_logo || "/static/img/logo-white.png";

	return (
		<>
			<Root>
				<CssBaseline />
				<GlobalStyle />
				<ErrorsApi />

				<Header open={open} setOpen={setOpenAndSave} />

				<Sidebar open={open} setSidebarOpen={setOpenAndSave} />

				{/* C'est très moche de faire du css comme ça, mais ça à le mérite de fonctionner... */}
				<Main className={classes.main} style={{ minHeight: `calc(100vh - ${footerHeight}px)` }}>
					<div className={classes.toolbar} onClick={() => history.push("/settings")}>
						<img className={classes.companyLogo} src={logo} alt="Logo de la société" />
					</div>
					{children}
				</Main>
			</Root>
			<Footer ref={footerRef} />
		</>
	);
}
