import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import SolContainer from "./Container";
import { Box } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { isEqual } from "lodash";

// #region Debug
const debug = false;
const debugStyle = debug
	? {
			paper: {
				// padding: '0.5em',
				// textAlign: 'center',
				// color: '#000',
				background: "hsla(210, 80%, 50%, .5)",
			},
			spacer: {
				// padding: '0.5em',
				// textAlign: 'center',
				// color: '#333',
				background: "transparent",
				border: "1px dashed",
			},
	  }
	: {};
const useStyles = makeStyles(debugStyle);
// #endregion Debug

function SolItem({ xs = 12, sm, md, lg, xl, parent, offset, order, children, width, ...rest }) {
	const classes = useStyles();

	// Props that are unique to grid container
	const { alignItems, justify, spacing, ...props } = rest;

	// Returns the lowercase version of a few types, including strings and objects
	// This is used because xs={"TRUE"} doesn't work with material-ui
	function lowerCaseAny(val) {
		// prop isn't a valid type? Return it as is
		if (val === undefined || val === null || typeof val === "number" || typeof val === "boolean") return val;
		// If it's a string, just return the lowercase version
		if (typeof val === "string") return val.toLowerCase();

		// For objects, iterate over each prop, returning its lowercase variant
		if (typeof val === "object") {
			for (let key in val) {
				val[key] = lowerCaseAny(val[key]);
			}
			return val;
		}
		// In any other case, try to cast the value as string and lowercase-it
		else return val.toString().toLowerCase();
	}
	xs = lowerCaseAny(xs);
	sm = lowerCaseAny(sm);
	md = lowerCaseAny(md);
	lg = lowerCaseAny(lg);
	xl = lowerCaseAny(xl);
	offset = lowerCaseAny(offset);
	order = lowerCaseAny(order);

	// Returns true if the value is a valid bool
	function isValidBool(bool) {
		// if typeof says bool, it is valid!
		if (typeof bool === "boolean") return true;
		// if it's a string and its value matches a bool
		else if (typeof bool === "string") {
			bool = bool.toLowerCase();
			return ["false", "true"].indexOf(bool) !== -1;
		}
		// In any other case, it's not a bool
		else return false;
	}

	// Returns a bool if the size property matches what is allowed by material-ui https://v4.mui.com/api/grid/
	// It is used in debug mode to only display the value if its correct
	function validSize(prop) {
		let int = parseInt(prop, 10);

		return (
			// Is it a bool ?
			isValidBool(prop) ||
			// Is it a number, between 1 and 12 (inclusive) ?
			(typeof prop === "number" && prop >= 1 && prop <= 12) ||
			(!isNaN(int) && int >= 1 && int <= 12) ||
			// is it "auto" ?
			prop === "auto"
		);
	}

	// Returns true if an offset is configured and the screen size matches
	function offsetNeeded() {
		let needOffsetXS = validSize(offset?.xs) && isWidthUp("xs", width);
		let needOffsetSM = validSize(offset?.sm) && isWidthUp("sm", width);
		let needOffsetMD = validSize(offset?.md) && isWidthUp("md", width);
		let needOffsetLG = validSize(offset?.lg) && isWidthUp("lg", width);
		let needOffsetXL = validSize(offset?.xl) && isWidthUp("xl", width);

		return needOffsetXS || needOffsetSM || needOffsetMD || needOffsetLG || needOffsetXL;
	}

	// Will wrap the content with another <Row> if parent={true}
	function WrapWithRow(content) {
		if (parent) {
			return (
				<SolContainer alignItems={alignItems} justify={justify} spacing={spacing}>
					{content}
				</SolContainer>
			);
		} else {
			return content;
		}
	}

	// Will wrap the content with a <Box> for the "order" prop to work
	// This is a workaround for Material-ui v4 and below
	// Check: https://github.com/mui/material-ui/issues/14570#issuecomment-1062690546
	function WrapWithBox(content) {
		if (order) {
			return (
				<Box clone order={order}>
					{content}
				</Box>
			);
		} else {
			return content;
		}
	}

	return (
		<>
			{/* Offset management */}
			{offsetNeeded() && (
				<Grid item xs={offset?.xs} sm={offset?.sm} md={offset?.md} lg={offset?.lg} xl={offset?.xl}>
					{debug && (
						<Paper className={classes.spacer} elevation={0}>
							{isWidthUp("xs", width) && validSize(offset?.xs) && `offsetXS:${offset?.xs} `}
							{isWidthUp("sm", width) && validSize(offset?.sm) && `offsetSM:${offset?.sm} `}
							{isWidthUp("md", width) && validSize(offset?.md) && `offsetMD:${offset?.md} `}
							{isWidthUp("lg", width) && validSize(offset?.lg) && `offsetLG:${offset?.lg} `}
							{isWidthUp("xl", width) && validSize(offset?.xl) && `offsetXL:${offset?.xl}`}
						</Paper>
					)}
				</Grid>
			)}

			{WrapWithBox(
				<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} {...props}>
					{(debug && (
						<Paper className={classes.paper}>
							{isWidthUp("xs", width) && validSize(xs) && `xs:${xs} `}
							{isWidthUp("sm", width) && validSize(sm) && `sm:${sm} `}
							{isWidthUp("md", width) && validSize(md) && `md:${md} `}
							{isWidthUp("lg", width) && validSize(lg) && `lg:${lg} `}
							{isWidthUp("xl", width) && validSize(xl) && `xl:${xl}`}

							<br />

							{order?.xs && `order.xs:${order?.xs} `}
							{order?.sm && `order.sm:${order?.sm} `}
							{order?.md && `order.md:${order?.md} `}
							{order?.lg && `order.lg:${order?.lg} `}
							{order?.xl && `order.xl:${order?.xl}`}

							{WrapWithRow(children)}
						</Paper>
					)) ||
						WrapWithRow(children)}
				</Grid>
			)}
		</>
	);
}

const GridSizes = PropTypes.oneOf([false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
const breakpoints = PropTypes.shape({
	xs: GridSizes,
	sm: GridSizes,
	md: GridSizes,
	lg: GridSizes,
	xl: GridSizes,
});

SolItem.propTypes = {
	hidden: PropTypes.bool,
	parent: PropTypes.bool,
	order: breakpoints,
	offset: breakpoints,
	...Grid.propTypes,
};

SolItem.defaultProps = {
	xs: 12,
};

function PropsAreEqual(prev, next) {
	return (
		prev.hidden === next.hidden &&
		prev.parent === next.parent &&
		isEqual(prev.order, next.order) &&
		isEqual(prev.offset, next.offset) &&
		isEqual(prev.children, next.children)
	);
}

export default React.memo(withWidth()(SolItem), PropsAreEqual);
