/**
 * Convention de nommage :
 * Les icônes finissent par "Icon"
 *
 * Les composants ont le même nom que leurs fichiers
 * Les pages listes finissent par "List"
 * Les pages détails finissent par "Details"
 *
 * Les routes finissent par "Route" quand children est à null
 * ou undefined, "Routes" quand children est un tableau
 *
 * Quand cela est possible, définir la route d'une liste au pluriel,
 * et les details au singulier
 */

import React from "react";
import Async from "../layouts/components/Async";

// #region Import des icônes
import { AccountCircle, Contacts, Settings } from "@material-ui/icons";
import { Users as UsersIcon } from "react-feather";
// #endregion Import des icônes

// #region Imports et définitions des routes du projet
// #region Composants de bases
// -- Imports --
const Login = Async(() => import("../pages/auth/Login"));
const Register = Async(() => import("../pages/auth/Register"));
const ResetPassword = Async(() => import("../pages/auth/ResetPassword"));
const Page404 = Async(() => import("../pages/auth/Page404"));
const Page500 = Async(() => import("../pages/auth/Page500"));
const PageNetworkError = Async(() => import("../pages/auth/PageNetworkError"));
// -- Définitions --
const authRoutes = {
	id: "auth",
	path: "/auth",
	icon: <UsersIcon />,
	children: [
		{
			path: "/login",
			name: "connexion",
			component: Login,
		},
		{
			path: "/register",
			name: "inscription",
			component: Register,
		},
		{
			path: "/reset-password",
			name: "resetPassword",
			component: ResetPassword,
		},
		{
			path: "/404",
			name: "pageNonTrouvée",
			component: Page404,
		},
		{
			path: "/500",
			name: "erreurInterne",
			component: Page500,
		},
		{
			path: "/networkerror",
			name: "erreurRéseau",
			component: PageNetworkError,
		},
	],
};
// #endregion Composants de bases

// #region Racine du site
const Home = Async(() => import("../pages/pages/Home"));
const homeRoute = {
	id: "profile",
	path: "/",
	icon: <AccountCircle />,
	component: Home,
	containsHome: true,
};
// #endregion Racine du site

// #region Profil
// -- Imports --
const Profile = Async(() => import("../pages/pages/Profile"));
// -- Définitions --
const profileRoutes = {
	id: "settings",
	path: "/settings",
	icon: <Settings />,
	component: Profile,
};
// #endregion Profil

// #region Annuaire
// -- Imports --
const ContactsDirectory = Async(() => import("../pages/pages/ContactsDirectory"));
// -- Définitions --
const contactsDirectoryRoutes = {
	id: "contact-directory",
	path: "/directory",
	icon: <Contacts />,
	component: ContactsDirectory,
};
// #endregion Annuaire

// #region Profils publique
// -- Imports --
const PublicProfile = Async(() => import("../pages/pages/PublicProfile"));
// -- Définitions --
const publicProfileRoutes = {
	id: "public-profile",
	path: "/p/:username",
	icon: <Contacts />,
	component: PublicProfile,
};
// #endregion Profils publique
// #endregion Imports et définitions des routes du projet

// #region Export des routes
// Routes de bases nécessaires au projet
export const auth = [authRoutes];

// Toutes les autres pages utilisées au cours du projet
// L'ordre n'as pas d'importance ici, mais il est toujours bon d'être organisé
export const dashboard = [homeRoute, profileRoutes, contactsDirectoryRoutes];

export const publicProfile = [publicProfileRoutes];

// Définition du menu (sidebar)
// L'ordre ici défini la structure du menu, c'est donc très important !
export default [homeRoute, profileRoutes];
// #endregion Export des routes
