import React from "react";
import styled from "styled-components";

import { CssBaseline } from "@material-ui/core";

const Root = styled.div`
	height: 100%;
	max-width: 520px;
	margin: 0 auto;
	padding: 0;
	display: flex;
	flex-direction: column;
`;

function Auth({ children }) {
	return (
		<Root>
			<CssBaseline />
			{children}
		</Root>
	);
}

export default Auth;
