// L'URL de l'API peut être modifiée dans le fichier public/config.js
export const API_URL = window.config.apiUrl;

const KEY = "API_TOKEN";

export const NAV_APP_CODE = "AWA";

export const setToken = (token) => localStorage.setItem(KEY, token);

export const getToken = () => localStorage.getItem(KEY);

export const isLoggedIn = () => {
	const token = getToken();
	if (token) return true;
	else {
		removeToken();
		return false;
	}
};

export const removeToken = () => localStorage.removeItem(KEY);

export const parseJwt = (token) => {
	// Récupération du token automatique
	if (typeof token !== "string") token = getToken();

	// Si la personne est connecté
	return token !== null ? JSON.parse(token) : null;
};
