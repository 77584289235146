import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	isWidthUp,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Toolbar,
	Typography,
	withWidth,
} from "@material-ui/core";
import { Save, Settings as SettingsIcon, Sync as SyncIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import { camelCase, clamp, inRange, orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { EnhancedTableHead, ExportExcel, IconButton, LoadingIconButton, Search } from ".";
import debounce from "../../api/debounce";
import { numberWithSpaces } from "../../api/numbers";
import { pascalCase } from "../../api/strings";
import variants from "../../theme/variants";
import "./EnhancedTable.css";

// #region Customisation de certains éléments
const Spacer = styled.div`
	flex: 1 1 20%;
`;

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
		"& .MuiTableCell-root": {
			whiteSpace: "nowrap",
		},
	},
}))(TableRow);
// #endregion Customisation de certains éléments

// #region Fonctions nécessaires au tableau
const parseLinkTo = (link, row) => {
	// On enlève le slash du début s'il y en a un
	if (link.indexOf("/") === 0) link = link.substr(1);

	// On remplace ":variable" avec row[variable]
	link = link
		.split(":")
		.map((arg, i) => {
			// on enlève tout les slash, car on va les ré-ajouter
			arg = arg.replace("/", "");
			// on renvoie toujours le module en premier (/tasks/...)
			if (i === 0) return arg;
			else if (row[arg]) return row[arg];
			else return "%00";
		})
		.join("/");

	return "/" + link;
};
// #endregion Fonctions nécessaires au tableau

// #region Gestion customisé des colonnes
export const DEFAULT_MIN_WIDTH_CELL = 80;
export const DEFAULT_MAX_WIDTH_CELL = 800;

const TableColumnPicker = (props) => {
	const { headCells, headCellsHandler } = props;
	const { t } = useTranslation();

	return (
		<FormGroup>
			{headCells.map((headCell) => {
				let label = headCell?.label || headCell?.id;

				if (!label || label === "") return null;

				return (
					<FormControlLabel
						key={label}
						label={t(camelCase(label))}
						control={<CustomCheckBox updateHandler={headCellsHandler} headCell={headCell} />}
					/>
				);
			})}
		</FormGroup>
	);
};

const CustomCheckBox = (props) => {
	const { headCell, updateHandler } = props;
	const [isActive, setIsActive] = useState(headCell.active === undefined ? true : headCell.active);

	const handleChange = () => {
		headCell.active = !isActive;
		setIsActive(headCell.active);
		updateHandler();
	};

	return <Checkbox checked={isActive} onClick={handleChange} label={headCell.label} />;
};

// Paramètre d'affichage
const ConfigDialog = ({ open, setOpen, headCells, handleHeadCellsChange }) => {
	const { t } = useTranslation();

	return (
		<Dialog open={open} onClose={() => setOpen(!open)}>
			<DialogTitle
				style={{
					backgroundColor: variants[0].palette.primary.main,
					color: variants[0].palette.primary.contrastText,
				}}
			>
				{t("translation:displaySettings")}
			</DialogTitle>

			<DialogContent>
				<TableColumnPicker headCells={headCells} headCellsHandler={handleHeadCellsChange} />
			</DialogContent>

			<DialogActions>
				<Button onClick={() => setOpen(!open)} color="secondary">
					{t("translation:close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
// #endregion Gestion customisé des colonnes

function EnhancedTable({
	id,
	headCells,
	setHeadCells,
	rows,
	rowsLink,
	rowsClick,
	rowsLinkColumn,
	externalLink,
	enumPrefix,
	rowsColor,
	rowsStyle,
	cellStyle,
	refetch,
	refetchLoading,
	disablePagination,
	...rest
}) {
	// Hooks
	const { t } = useTranslation();

	// On force le titre du tableau en camelCase pour i18next
	id = camelCase(id);

	// #region State du tableau
	// Tri
	const [orderData, setOrderData] = useState(rest?.orderData || ""); // Sur quelle(s) colonne(s) faire le tri ?
	const [orderDir, setOrderDir] = useState(rest?.orderDir || "desc"); // Direction de/des colonne(s) à trier
	// Pagination
	const [page, setPage] = useState(0); // À quel page est-on ?
	const [rowsPerPage, setRowsPerPage] = useState(25); // Lignes par page
	// Filtre
	const [searchFilteredRows, setSearchFilteredRows] = useState(); // Les lignes avec tous les filtres appliqués
	// Autre
	const [dialog, setDialog] = useState(false); // Ouverture / fermeture des paramètres d'affichage
	// #endregion State du tableau

	// #region Redimensionnement des colonnes
	const isResizing = useRef(-1); // Quelle est la colonne en cours de resizing
	const initialX = useRef(0); // Postion X de la souris au debut du resizing

	// References des colonnes affichées pour acceder à la width initiale
	const headCellsRef = headCells.map(() => createRef());

	// mise à jour de l'objet headCells avec les width's au premier render
	useLayoutEffect(() => {
		setHeadCells((prevHeadCells) =>
			prevHeadCells.map((col, indx) => {
				if (!!col?.active && headCellsRef[indx]?.current?.clientWidth) {
					// console.log("mise à jour");
					col.width = clamp(headCellsRef[indx].current.clientWidth, DEFAULT_MIN_WIDTH_CELL, DEFAULT_MAX_WIDTH_CELL);
				}
				return col;
			})
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Interception des evenements de la souris
	useEffect(() => {
		// loadColumnInfoLocalStorage();
		document.onmousemove = handleOnMouseMove;
		document.onmouseup = handleOnMouseUp;
		return () => {
			document.onmousemove = null;
			document.onmouseup = null;
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const adjustWidthColumn = (index, width, offset) => {
		// On ne met à jours la largeur que si on est dans les limites
		if (!inRange(width, DEFAULT_MIN_WIDTH_CELL, DEFAULT_MAX_WIDTH_CELL + 1)) return;

		// La largeur de la colonne est contenue entre les valeurs Min et Max
		const newWidth = clamp(width, DEFAULT_MIN_WIDTH_CELL, DEFAULT_MAX_WIDTH_CELL);

		// la nouvelle position equivaut à l'ancienne + le decalage
		initialX.current += offset;

		// On ajuste l'index de headCells pour correspondre aux elements affichés
		// par un décalage (+1) pour chaque element masqué avant le curseur
		headCells.forEach((col, colindex) => {
			if (col?.active === false && index >= colindex) index++;
		});

		// L'objet headCells est mis à jour avec la nouvelle width
		setHeadCells((prevHeadCells) =>
			prevHeadCells.map((col, indx) => {
				if (indx === index) {
					col.width = newWidth;
				}
				return col;
			})
		);
	};

	const setCursorDocument = (isResizing) => {
		document.body.style.cursor = isResizing ? "col-resize" : "auto";
	};

	// Calcul des ajustements quand on resize une colonne
	const handleOnMouseMove = (e) => {
		// Si on modifie une colonne
		if (isResizing.current >= 0) {
			// on calcul le décalage par rapport à la position précedente de la souris
			const offset = e.clientX - initialX.current;
			// et on l'ajoute à la width actuelle
			const newWidth = (headCells.filter((cell) => cell?.active ?? true)[isResizing.current].width ?? DEFAULT_MIN_WIDTH_CELL) + offset;
			adjustWidthColumn(isResizing.current, newWidth, offset);
		}
	};

	const handleOnMouseUp = () => {
		isResizing.current = -1;
		setCursorDocument(false);
	};

	const onClickResizeColumn = (index, x) => {
		isResizing.current = index;
		initialX.current = x;
		setCursorDocument(true);
	};
	// #endregion

	// #region Functions de handle
	function handleSearchFilterChanging(filter) {
		let filteredArray = rows?.filter((row) => {
			const words = filter.split(" ");
			let found = true;

			words.forEach((word) => {
				if (word !== "") {
					let search = headCells
						.filter((cell) => cell?.active ?? true)
						.map((cell) => {
							switch (cell.type) {
								case "string":
									return row[cell.id]?.toLowerCase();
								case "bool":
								case "int":
									return row[cell.id]?.toString().toLowerCase();
								case "float":
									return numberWithSpaces(row[cell.id]) ?? "";
								case "enum":
									return t(`translation:${enumPrefix || ""}${pascalCase(cell.id)}${pascalCase(row[cell.id])}`);
								case "date":
									return new Date(row[cell.id]).getTime() >= 0 ? new Date(row[cell.id]).toLocaleDateString() : "";
								case "custom":
									return cell.searchTerm?.({ row })?.toLowerCase() || "";

								default:
									console.warn(`Unsupported cell type: "${cell.type}"`);
									return row[cell.id];
							}
						})
						.join("|");

					if (!search.toLowerCase().includes(word.toLowerCase())) found = false;
				}
			});

			return found;
		});

		setSearchFilteredRows(filteredArray);
		setPage(0); // on reviens au début de la liste
	}

	function handleHeadCellsChange() {
		let tempHeadCells = headCells.map((item) => item);
		setHeadCells(tempHeadCells);
	}

	// Gestion du tri des données lors du click sur une colonne
	function handleRequestSort(event, property) {
		const isAsc = orderData === property && orderDir === "asc" ? "desc" : "asc";

		// Si shift est maintenu, on créer un tri multiple
		if (event.shiftKey) {
			// Si c'est le premier shift+click, on passe d'une string à un tableau
			if (typeof orderData === "string") {
				setOrderData([orderData, property]);
				setOrderDir([orderDir, isAsc]);
			} else {
				// orderData est un tableau, les opérations se complexifie car il faut vérifier si la property existe déjà
				let orderDirIndex = orderData.indexOf(property);

				// Si la propriétée existe déjà dans la liste, on ne l'ajoute pas, on inverse juste sa direction
				if (orderDirIndex !== -1) {
					orderDir[orderDirIndex] = orderDir[orderDirIndex] === "asc" ? "desc" : "asc";
					setOrderDir([...orderDir]);
				} else {
					// Si la proritétée n'existe pas déjà, il suffit de la rajouter à la fin de la liste
					setOrderData([...orderData, property]);
					setOrderDir([...orderDir, "desc"]);
				}
			}
		} else {
			// Création d'un tri simple
			setOrderDir(isAsc);
			setOrderData(property);
		}
	}

	function handleChangePage(event, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		let tempRowsPerPage = parseInt(event.target.value, 10);
		setRowsPerPage(tempRowsPerPage);
		setPage(0);
	}

	function handleOpenExternalLink(link) {
		if (typeof link !== "string" || link === "") return;
		window.open(link);
	}
	// #endregion Functions de handle

	// #region Initialisation des colonnes filtrés
	useEffect(() => {
		if (rows === undefined || rows === null) return;
		setSearchFilteredRows(rows);
		setPage(0);

		// Si on désactive la pagination, toujours afficher toutes les lignes
		if (disablePagination) setRowsPerPage(rows.length);
	}, [rows, disablePagination]);
	// #endregion Initialisation des colonnes filtrés

	return (
		<Paper>
			<ConfigDialog open={dialog} setOpen={setDialog} headCells={headCells} handleHeadCellsChange={handleHeadCellsChange} />

			<Toolbar>
				<Spacer />

				{refetch && <LoadingIconButton onClick={refetch} loading={refetchLoading} icon={<SyncIcon />} title={t("translation:refetch")} />}
				<Search searchFilterChanging={debounce(handleSearchFilterChanging, 300)} />
				<ExportExcel title={t(id)} headers={headCells} values={searchFilteredRows} />
				<IconButton title={t("translation:tableSettings")} icon={<SettingsIcon />} onClick={() => setDialog(!dialog)} />
			</Toolbar>

			<TableContainer
				style={{ maxHeight: "57vh", maxWidth: `calc(100vw - ${isWidthUp("lg", rest?.width) ? 130 : isWidthUp("sm", rest?.width) ? 90 : 40}px)` }}
			>
				<Table className="table" stickyHeader id={id} aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
					<EnhancedTableHead
						orderData={orderData}
						orderDir={orderDir}
						headCells={headCells}
						onRequestSort={handleRequestSort}
						rowCount={searchFilteredRows !== undefined ? searchFilteredRows.length : rowsPerPage}
						headCellsRef={headCellsRef}
						onClickResizeColumn={onClickResizeColumn}
					/>
					<TableBody>
						{(() => {
							if (searchFilteredRows?.length > 0) {
								return orderBy(searchFilteredRows, orderData, orderDir)
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										if (typeof rowsStyle === "function") row.style = rowsStyle(row);
										else row.style = rowsStyle;

										if (!!rowsLink) {
											row.style = { ...row.style, textDecoration: "none" };
										}

										if (!!rowsClick) {
											row.style = { ...row.style, textDecoration: "none" };
										}

										return (
											<StyledTableRow
												key={headCells
													.filter((cell) => cell?.primary)
													.map((cell) => row[cell.id])
													.join("-")}
												{...(!!rowsLink &&
													!rowsLinkColumn && {
														component: Link,
														to: parseLinkTo(rowsLink, row),
														style: { textDecoration: "none" },
													})}
												{...(!!rowsClick && {
													style: { textDecoration: "none", color: "cyan" },
												})}
												{...(!!externalLink &&
													row[externalLink] !== "" && {
														component: "a",
														href: row[externalLink],
														target: "_blank",
														onClick: () => handleOpenExternalLink(row[externalLink]),
														style: { textDecoration: "none" },
													})}
												style={row?.style}
												hover
												tabIndex={-1}
											>
												{headCells
													.filter((cell) => cell?.active ?? true)
													.map((cell) => {
														if (typeof rowsColor === "function") cell.color = rowsColor(row);

														if (typeof cellStyle === "function") row.cellStyle = cellStyle({ row, cell });
														else row.cellStyle = cellStyle;

														if (cell?.align === undefined && ["float", "int"].indexOf(cell?.type) !== -1) cell.align = "right";

														return (
															<TableCell
																key={cell.id}
																align={cell.align}
																style={{ color: cell?.color || "", ...row?.cellStyle }}
																className="resizableTableCell"
																{...(!!rowsLink &&
																	rowsLinkColumn === cell.id && {
																		component: Link,
																		to: parseLinkTo(rowsLink, row),
																	})}
																{...(!!rowsClick &&
																	rowsLinkColumn === cell.id && {
																		component: Link,
																		to: parseLinkTo(rowsClick, row),
																	})}
															>
																{(() => {
																	let date = new Date(row?.[cell.id]);

																	switch (cell.type) {
																		case "date":
																			if (date?.getTime() >= 0) return date.toLocaleDateString();
																			else return null;

																		case "enum":
																			return t(camelCase(`${enumPrefix || ""}${cell.id}-${row[cell.id]}`));

																		case "bool":
																			return <Checkbox checked={row?.[cell.id]} disabled />;

																		case "custom":
																			return cell?.content({
																				rows,
																				row,
																				no: rows.indexOf(row),
																				handleOpenExternalLink,
																			});

																		case "float":
																			return numberWithSpaces(row[cell.id]);

																		case "string":
																		case "int":
																		default:
																			return row?.[cell.id];

																		case "save":
																			return (
																				<LoadingIconButton
																					disabled={row?._loading}
																					loading={row?._loading === true}
																					icon={<Save />}
																					title={t("translation:save")}
																					onClick={() => {
																						// Index de la ligne courante
																						let loadingIndex = rows.indexOf(row);
																						// On modifie les lignes pour ne mettre la propriété _loading qu'à la ligne courante
																						setSearchFilteredRows(
																							rows.map((currRow, index) => (index === loadingIndex ? { ...currRow, _loading: true } : currRow))
																						);

																						cell.submit(row);
																					}}
																				/>
																			);
																	}
																})()}
															</TableCell>
														);
													})}
											</StyledTableRow>
										);
									});
							} else if (rows === undefined) {
								return [...Array(rowsPerPage)].map((e, i) => (
									<StyledTableRow hover tabIndex={-1} key={i}>
										{headCells
											.filter((cell) => cell?.active ?? true)
											.map((cell) => (
												<TableCell key={cell.id}>
													<Skeleton variant="text" />
												</TableCell>
											))}
									</StyledTableRow>
								));
							} else {
								return (
									<TableRow>
										<TableCell colSpan={headCells.filter((headCell) => headCell?.active ?? true).length} align="center">
											<Typography variant="body1" component="p">
												{t("translation:noData")}
											</Typography>
										</TableCell>
									</TableRow>
								);
							}
						})()}
					</TableBody>
				</Table>
			</TableContainer>

			{!disablePagination && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={searchFilteredRows?.length !== undefined ? searchFilteredRows.length : rowsPerPage}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
}

EnhancedTable.propTypes = {
	// Identifiant unique du tableau, utilisé nottament pour générer le nom du fichier Excel exporté
	id: PropTypes.string.isRequired,

	// Tri des données, peut se faire sur une ou plusieurs colonnes
	// OrderData = nom de la/les colonne(s) sur lesquels faire le tri
	// OrderDir = direction (croissant, décroissant)
	orderData: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	orderDir: PropTypes.oneOfType([PropTypes.oneOf(["desc", "asc"]), PropTypes.arrayOf(PropTypes.oneOf(["desc", "asc"]))]),

	// Définition des colonnes
	headCells: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired, // Le nom de la propriété de l'objet à récupérer
			align: PropTypes.oneOf(["center", "inherit", "justify", "left", "right"]),
			active: PropTypes.bool, // Permet d'afficher ou non la colonne
			type: PropTypes.oneOf(["string", "bool", "float", "int", "date", "enum", "custom", "save"]),
			primary: PropTypes.bool, // Si c'est une clé primaire, on peut l'utiliser pour générer un lien vers la fiche détaillé
			content: (props, propName) => {
				if (props.type === "custom" && typeof props[propName] !== "function")
					return new Error(`if headCell.type = "custom", you need a "content({row, rows})" function to render the value!`);
			},
			searchTerm: PropTypes.func, // Permet de définir un mot clé de recherche custom pour les types de données plus "exotiques"
			width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Force la largeur de la colonne avec la propriété CSS : `width`
		})
	).isRequired,
	setHeadCells: PropTypes.func.isRequired,
	rows: (props, propName, componentName) => {
		let value = props[propName];

		if (value !== undefined && !Array.isArray(value))
			return new Error(
				`Invalid prop \`${propName}\` of type \`${typeof value}\` supplied to \`${componentName}\`. Expected either undefined or an array.`
			);
	},
	rowsLink: PropTypes.string, // Lien vers la fiche détaillé
	externalLink: PropTypes.func, // Lien externe au projet
	enumPrefix: PropTypes.string, // Prefix des enums pour les traductions

	// Customisation du rendu du tableau par ligne / cellule
	rowsColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	rowsStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
	cellStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),

	// Configuration de la synchronisation des données une fois le tableau déjà remplis
	noRefetch: (props, propName, componentName) => {
		// Si refetch est faux ou non précisé
		if (!props?.[propName]) {
			let refetchType = typeof props?.refetch;
			let refetchLoadingType = typeof props?.refetchLoading;
			let setRefetchLoadingType = typeof props?.setRefetchLoading;

			if (refetchType !== "function") {
				return new Error(`<${componentName} refetch={${refetchType}}> expected a function. You can also set noRefetch={true}`);
			}
			if (refetchLoadingType !== "boolean") {
				return new Error(`<${componentName} refetchLoading={${refetchLoadingType}}> expected a boolean. You can also set noRefetch={true}`);
			}
			if (setRefetchLoadingType !== "function") {
				return new Error(`<${componentName} setRefetchLoading={${setRefetchLoadingType}}> expected a function. You can also set noRefetch={true}`);
			}
		}
	},
	// refetch: PropTypes.func,
	// refetchLoading: PropTypes.bool,
	disablePagination: PropTypes.bool,
};

export default withWidth()(EnhancedTable);
