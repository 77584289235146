import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid as MuiGrid, Typography as MuiTypography } from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { display, spacing } from "@material-ui/system";
import { camelCase } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { pascalCase } from "../../api/strings";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(display);

export default function Collapse({ model, title, expandByDefault = false, expandDisabled, ...rest }) {
	const { t } = useTranslation();

	model = camelCase(model);
	title = pascalCase(title);
	const [expanded, setExpanded] = useState(expandByDefault);

	const handleExpandChange = (expanded) => {
		if (!expandDisabled) {
			setExpanded(expanded);
		}
	};

	return (
		<ExpansionPanel expanded={expanded} onChange={(_, newExpanded) => handleExpandChange(newExpanded)} {...rest}>
			<ExpansionPanelSummary expandIcon={!expandDisabled && <ExpandMoreIcon />}>
				<Typography variant="h3">{model ? t(`translation:${model}Section${title}`) : t(`translation:section${title}`)}</Typography>
			</ExpansionPanelSummary>

			<ExpansionPanelDetails>
				<Grid container alignItems="flex-start" spacing={4}>
					{rest.children}
				</Grid>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
}

Collapse.propTypes = {
	model: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	expandByDefault: PropTypes.bool,
	expandDisabled: PropTypes.bool,
};

Collapse.defaultProps = {
	expandByDefault: false,
};
