import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import React, { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import { getDevMode } from "./api/localStorage";
import { UserContext, UserProvider } from "./api/users";
import { EditModeProvider } from "./hooks/useEditMode";
import Routes from "./routes/Routes";
import maTheme, { generateTheme } from "./theme";
import { getThemeFromUser } from "./theme/variants";

function UpdateTheme({ theme, children }) {
	const { i18n } = useTranslation();

	const { user } = useContext(UserContext);
	const [customTheme, setCustomTheme] = useState(maTheme[theme.currentTheme](i18n.language));

	// À chaque fois que l'utilisateur change, on refait le theme en appliquant les couleurs de la société
	useEffect(() => {
		setCustomTheme(generateTheme(getThemeFromUser(user), i18n.language));
	}, [user]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<MuiThemeProvider theme={customTheme}>
			<ThemeProvider theme={customTheme}>{children}</ThemeProvider>
		</MuiThemeProvider>
	);
}

function App({ theme }) {
	const { i18n, t } = useTranslation();

	if (getDevMode()) {
		window.i18n = i18n;
		window.t = t;
	}

	return (
		<React.Fragment>
			<Helmet titleTemplate="%s | Entrepros" defaultTitle="Entrepros" />
			<StylesProvider injectFirst>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<UserProvider>
						<UpdateTheme theme={theme}>
							<EditModeProvider>
								<Routes />
							</EditModeProvider>
						</UpdateTheme>
					</UserProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</React.Fragment>
	);
}

export default connect((store) => ({ theme: store.themeReducer }))(App);
