// Déclaration statique de tout les fichiers JSON pour que le compilateur React les mettent dans le bundle final

import activityAccountEntry from "./LangActivityAccountEntry.json";
import activityReport from "./LangActivityReport.json";
import charge from "./LangCharge.json";
import chargesToValidate from "./LangCharges.json";
import commercialAgreement from "./LangCommercialAgreement.json";
import companyInformation from "./LangCompanyInformation.json";
import contact from "./LangContact.json";
import contractor from "./LangContractor.json";
import contractorFilter from "./LangContractorFilter.json";
import contractorRelationships from "./LangContractorRelations.json";
import conventions from "./LangConventions.json";
import countries from "./LangCountries.json";
import currencies from "./LangCurrencies.json";
import customer from "./LangCustomer.json";
import dashboard from "./LangDashboard.json";
import documents from "./LangDocuments.json";
import entriesCorrections from "./LangEntriesCorrections.json";
import ged from "./LangGED.json";
import general from "./LangGeneral.json";
import importedWage from "./LangImportedWage.json";
import invoiceRequest from "./LangInvoiceRequest.json";
import login from "./LangLogin.json";
import payments from "./LangPayments.json";
import salesCrMemo from "./LangSalesCrMemo.json";
import salesInvoiced from "./LangSalesInvoiced.json";
import settings from "./LangSettings.json";
import support from "./LangSupport.json";
import training from "./LangTraining.json";
import languages from "./Languages.json";
import validation from "./LangValidation.json";
import wage from "./LangWage.json";
import workContract from "./LangWorkContract.json";

export default {
	langs: {
		FRA: "fr",
		ENU: "en",
	},
	namespaces: {
		activityAccountEntry,
		activityReport,
		charge,
		chargesToValidate,
		commercialAgreement,
		companyInformation,
		contact,
		contractor,
		contractorFilter,
		contractorRelationships,
		conventions,
		countries,
		currencies,
		customer,
		dashboard,
		documents,
		entriesCorrections,
		ged,
		general,
		importedWage,
		invoiceRequest,
		login,
		payments,
		salesCrMemo,
		salesInvoiced,
		settings,
		support,
		training,
		languages,
		validation,
		wage,
		workContract,
	},
};
