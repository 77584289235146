import React, { createContext, useContext, useState } from "react";

const EditModeContext = createContext(false);

export function EditModeProvider({ children }) {
	const [isEditMode, setIsEditMode] = useState(false);

	return <EditModeContext.Provider value={{ isEditMode, setIsEditMode }}>{children}</EditModeContext.Provider>;
}

export const useEditMode = () => useContext(EditModeContext);
