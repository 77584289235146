import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { PropTypes } from "prop-types";
import { isEqual } from "lodash";

// #region Debug
const debug = false;
const styles = debug
	? {
			container: {
				// boxShadow: 'inset 0 0 0 1px #0005',
				// borderRadius: '4px',
				background: "hsla(260, 80%, 60%, .2)",
			},
	  }
	: {};
const useStyles = makeStyles(styles);
// #endregion Debug

function SolContainer({ hidden, ...rest }) {
	const classes = useStyles();

	const { xs, sm, md, lg, xl, ...props } = rest;
	if (xs || sm || md || lg || xl) {
		console.error("Responsive breakpoints should not be used on Rows");
	}

	// Allow rows to be hidden for demo purposes, but can still be useful later on
	if (hidden) return null;

	return (
		<Grid container spacing={2} {...props} className={classes.container}>
			{props.children}
		</Grid>
	);
}

SolContainer.propTypes = {
	hidden: PropTypes.bool,
	...Grid.propTypes,
};

function PropsAreEqual(prev, next) {
	return prev.hidden === next.hidden && isEqual(prev.children, next.children);
}

export default React.memo(SolContainer, PropsAreEqual);
