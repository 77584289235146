import { Card, Grid, Typography } from "@material-ui/core";
import { NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon, OpenInNew as OpenInNewIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";
import { IconButton } from ".";

const TextHeader = styled.div`
	padding: ${(props) => props.theme.spacing(6)}px;
`;

const ButtonsHeader = styled.div`
	padding: ${(props) => props.theme.spacing(3)}px;
	text-align: right;
`;

const Wrapper = styled.div`
	canvas {
		width: 100% !important;
		height: auto !important;
	}
`;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function DocumentViewer({ url, onError, ...props }) {
	const { t } = useTranslation();

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(0);
	const [error, setError] = useState(null);

	const onLoadSuccess = ({ numPages }) => setNumPages(numPages);

	const onLoadError = (error) => {
		setError(error.message);
		onError(error);
	};

	return (
		<Card>
			<Grid container justify="center">
				{/* Pagination */}
				<Grid item xs={12} md={5}>
					<TextHeader>
						<Typography variant="body2">
							{(() => {
								if (error !== null) {
									return t("translation:pdfDocumentError");
								} else {
									if (numPages === null) return t("translation:noData");
									else return t("translation:pageN", { current: pageNumber + 1, total: numPages });
								}
							})()}
						</Typography>
					</TextHeader>
				</Grid>

				{/* Boutons */}
				<Grid item xs={12} md={7}>
					<ButtonsHeader>
						<IconButton
							icon={<NavigateBeforeIcon />}
							title={t("translation:previousPage")}
							onClick={() => setPageNumber((current) => current--)}
							disabled={error !== null || numPages === 0 || pageNumber === 0}
						/>
						<IconButton
							icon={<NavigateNextIcon />}
							title={t("translation:nextPage")}
							onClick={() => setPageNumber((current) => current++)}
							disabled={error !== null || numPages === 0 || pageNumber === numPages - 1}
						/>
						<IconButton
							icon={<OpenInNewIcon />}
							title={t("translation:openNewTab")}
							href={url}
							target="_blank"
							rel="noopener noreferrer"
							disabled={numPages === 0}
						/>
					</ButtonsHeader>
				</Grid>

				{/* Document */}
				<Grid item xs={12}>
					<Wrapper>
						<Document file={url} onLoadSuccess={onLoadSuccess} onLoadError={onLoadError} noData="" loading={t("translation:pdfLoading")} error="">
							<Page pageIndex={pageNumber} onLoadError={onError} loading="" noData="" error="" {...props} />
						</Document>
					</Wrapper>
				</Grid>
			</Grid>
		</Card>
	);
}
